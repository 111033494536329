import { lazy } from 'react';

//HTML
const Home = lazy(() => import('../pages/Home'));
const Login = lazy(() => import('../pages/Login'));
const Signup = lazy(() => import('../pages/Signup'));
const VerificationCode = lazy(() => import('../pages/VerificationCode'));
const VerificationOTP = lazy(() => import('../pages/VerificationOTP'));
const ForgotPassword = lazy(() => import('../pages/ForgotPassword'));
const MyAccount = lazy(() => import('../pages/MyAccount'));
const MyLists = lazy(() => import('../pages/MyLists'));
const SnapPicture = lazy(() => import('../pages/SnapPicture'));
const ProductRecognized = lazy(() => import('../pages/ProductRecognized'));
const ProductBrandRecognized = lazy(() => import('../pages/ProductBrandRecognized'));
const ProductDetails = lazy(() => import('../pages/ProductDetails'));
const GetQuote = lazy(() => import('../pages/GetQuote'));
const GetSample = lazy(() => import('../pages/GetSample'));
const Page404 = lazy(() => import('../pages/404'));
const Page500 = lazy(() => import('../pages/500'));
const ChangePassword = lazy(() => import('../pages/ChangePassword'));
const ResetPassword = lazy(() => import('../pages/ResetPassword'));
const UpdateProfile = lazy(() => import('../pages/UpdateProfile'));
const CameraSnap = lazy(() => import('../pages/CameraSnap'));
const SavedProducts = lazy(() => import('../pages/SavedProducts'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('../pages/TermsOfService'));


// WEB
const ViewAllProducts = lazy(() => import('../pages/ViewAllProducts'));
const SearchProductsResults = lazy(() => import('../pages/SearchProductsResults'));

const appRoutes = [
	{ path: '/', element: Home },	
	{ path: '/login', element: Login },
	{ path: '/sign-up', element: Signup },
	{ path: '/verification-code', element: VerificationCode },
	{ path: '/verification-otp', element: VerificationOTP },
	{ path: '/forgot-password', element: ForgotPassword },
	{ path: '/reset-password', element: ResetPassword },
	{ path: '/change-password', element: ChangePassword },
	{ path: '/my-account', element: MyAccount },
	{ path: '/my-lists', element: MyLists },
	{ path: '/snap-a-picture', element: SnapPicture },
	{ path: '/product-recognized', element: ProductRecognized },
	{ path: '/product-brand-recognized', element: ProductBrandRecognized },
	{ path: '/product-details/:productId', element: ProductDetails },
	{ path: '/get-a-quote/:productId', element: GetQuote },
	{ path: '/get-a-sample/:productId', element: GetSample },
	{ path: '/camera-snap', element: CameraSnap },
	{ path: '/404', element: Page404 },
	{ path: '/500', element: Page500 },
	{ path: '/update-profile', element: UpdateProfile },
	{ path: '/saved-products/:listId', element: SavedProducts },

	{ path: '/privacy-policy', element: PrivacyPolicy },
	{ path: '/terms-service', element: TermsOfService },

	// WEB
	{ path: '/view-products', element: ViewAllProducts },
	{ path: '/search-results', element: SearchProductsResults },
	
];

const userRoutes = [
	//{ path: 'profile', element: Profile },
]

const noUserRoutes = [
	// { path: '/login', element: Login },
	// { path: '/sign-up', element: Signup },
	// { path: '/verification-code', element: VerificationCode },
	// { path: '/verification-otp', element: VerificationOTP },
	// { path: '/forgot-password', element: ForgotPassword },
	//{ path: 'login', element: Login },
]

export { appRoutes, userRoutes, noUserRoutes };